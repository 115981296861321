.help-modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  height: 50px;
}

.help-modal-close-btn {
  float: right;
  margin-top: 7px !important;
}

.help-modal-content {
  padding-left: 25px;
  padding-right: 25px;
}

.help-circle {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  margin-left: 1px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.help-blue-circle {
  background-color: #A3D8FF;
  border: 1px solid #8ecfff;
}

.help-pink-circle {
  background-color: #FF76CE;
  border: 1px solid #ff63c7;
}

.help-no-results {
  vertical-align: middle;
  margin-right: 2px;
}

.help-settings-icon {
  vertical-align: middle;
}

.help-modal-footer {
  border-top: 1px solid rgba(0, 0, 0, .12);
  height: 36px;
  padding-right: 20px !important;
}