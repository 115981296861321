.game-over-modal {
  text-align: center;
}

.game-over-modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  height: 36px;
}

.close-btn {
  float: right;
}

.close-icon {
  color: #9e9e9e;
}


.content {
  width: 100%;
  height: 130px;
  position: absolute;
}

.content-header-small {
  font-size: 4vh;
  margin-bottom: 12px;
}

.content-solution-small {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 4vh;
}

.content-win-streak {
  font-size: 2vh;
  margin-top: 10px;
}

#canvas {
  width: 100%;
  height: 130px;
}

.reset-btn {
  margin-bottom: 15px !important;
}