.number-pad-half-view {
  min-width: 120px;
  max-width: 150px;
  margin-right: auto;
  margin-left: 5px;
}

.number-pad-row-half-view {
  display: flex;
  justify-content: space-between;
}

.number-half-view {
  width: 33px;
  height: 33px;
  line-height: 33px;
  border: 1px solid #adcc7b;
  border-radius: 25px;
  text-align: center;
  margin-bottom: 5px;
  font-size: 20px;
  background-color: #dce9c5;
}

.backspace-icon-half-view {
  height: 33px !important;
  font-size: 18px !important;
  margin-right: 1px !important;
}

.enter-half-view {
  font-size: 10px;
}

.number-pad-full-view {
  margin-left: auto;
  margin-right: auto;
  width: 340px;
}

.number-pad-row-full-view {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.number-full-view {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #adcc7b;
  border-radius: 25px;
  text-align: center;
  margin-bottom: 5px;
  font-size: 36px;
  background-color: #dce9c5;
}

.backspace-icon-full-view {
  height: 50px !important;
  font-size: 24px !important;
  margin-right: 2px !important;
}

.enter-full-view {
  font-size: 14px;
}

/* Small View*/
@media only screen and (max-width: 370px), screen and (max-height: 659px) {
  .number-pad-full-view {
    width: 310px;
  }

  .number-full-view {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    font-size: 28px;
  }

  .backspace-icon-full-view {
    height: 40px !important;
    font-size: 16px !important;
  }
  
  .enter-full-view {
    font-size: 10px;
  }
}