.game {
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 10px;
}

.header {
  display: flex;
  margin-top: 10px;
}

.placeholder {
  width: 72px;
  height: 21px;
  color: white;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
}

.title {
  flex-grow: 4;
  font-size: 32px;
  font-weight: bold;
}

.menu-btns {
  height: 38px;
  margin-top: 10px;
  margin-right: 10px;
}

.action-btn {
  height: 38px;
}

.action-btn:hover {
  cursor: pointer;
}

.row {
  display: flex;
  justify-content: center;
}

.result {
  flex-shrink: 0;
  flex-basis: 110px;
  border-left: 1px solid #9a9a9a;
  border-right: 1px solid #9a9a9a;
  margin-right: 8px;
  padding-top: 6px;
  padding-left: 6px;
}

.result-first {
  border-top: 1px solid #9a9a9a;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.result-last {
  border-bottom: 1px solid #9a9a9a;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.no-results {
  font-size: 12px;
  font-style: italic;
  line-height: 48px;
  padding-left: 4px;
  padding-right: 2px;
}

.no-results-icon {
  float: left !important;
}

.guess {
  display: flex;
  margin-bottom: 6px;
  margin-top: 6px;
  justify-content: space-between;
  flex-basis: 250px;
}

.guess-3 {
  max-width: 185px;
}

.guess-4 {
  max-width: 300px;
}

.column {
  border: 1px solid #9a9a9a;
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.column-selected {
  border: 1px solid black;
}

.num {
  font-size: 36px;
  margin-top: 2px;
}

.circles {
  display: flex;
  margin-bottom: 5px;
}

.blue-circle {
  width: 20px;
  height: 20px;
  background-color: #A3D8FF;
  border-radius: 50px;
  margin-right: 5px;
  border: 1px solid #8ecfff;
}

.pink-circle {
  width: 20px;
  height: 20px;
  background-color: #FF76CE;
  border-radius: 50px;
  margin-right: 5px;
  border: 1px solid #ff63c7;
}

.selected-views {
  display: flex;
  height: 160px;
}

.selected-views-toggle {
  width: 360px;
  margin: auto;
}

.selected-views-toggle-btn {
  font-size: .75em !important;
  padding: 4px !important;
  float: right !important;
}

.notes {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 5px;
  width: 188px;
}

.notes-btn {
  margin-left: 285px !important;
}

.notes-edit {
  margin: auto !important;
}

/* Small View*/
@media only screen and (max-width: 370px), screen and (max-height: 659px) {
  .column {
    width: 40px;
    height: 40px;
  }

  .guess-3 {
    max-width: 155px;
  }

  .guess-4 {
    max-width: 220px;
  }

  .num {
    font-size: 28px;
  }

  .selected-views-toggle {
    width: 330px;
  }

  .notes {
    width: 160px;
  }
}
