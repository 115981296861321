.reset-game-modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  height: 36px;
}

.reset-game-modal-content {
  padding-left: 25px;
  padding-right: 25px;
}

.reset-confirmation-btn {
  float: right;
}