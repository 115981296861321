.settings-modal {
  min-width: 300px;
}

.settings-modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  height: 36px;
}

.settings-modal-content {
  padding: 25px;
}

.settings-modal-content-title {
  font-weight: bold;
  text-align: left;
  margin-bottom: 5px;
}

.statistics {
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 200px;
}

.statistics-row {
  border: 1px solid black;
}

.statistics-row-title {
  padding-left: 5px;
}

.statistics-row-data {
  text-align: right;
  padding-right: 5px;
}

.share-btn {
  box-shadow: none !important;
  border-radius: 25px !important;
  font-size: 12px !important;
  height: 30px;
  margin-top: 10px !important;
  width: 150px;
}

.share-icon {
  font-size: 16px !important;
  margin-left: 5px;
}

.level-selection {
  margin-bottom: 15px;
}

.level-btn {
  height: 40px !important;
}

.level-hint {
  font-size: 12px;
  color: #595959;
  margin-top: 3px;
}

.color-blind-friendly {
  display: flex;
}

.color-blind-friendly-label {
  align-content: center;
}